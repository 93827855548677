import { InfoItem, ToggleButton } from '@frk/eds-components';
import {
  NAV,
  PricingHistory,
  ShareClass,
  SalesChargeBreakPoint,
  Yields,
} from '@models';
import { DistributionHistory } from '@products/models/distribution-history';
import { FundId, ProductType, ShareClassCode, SiteConfig } from '@types';
import dayjs from 'dayjs';
import { PremiumDiscountAnalysisData } from '@products/pricing-distribution/premium-discount-analysis/premium-discount-analysis.component';
import { PremiumDiscountElevatedPeriod } from '@products/pricing-distribution/premium-discount-analysis/premium-discount-elevated/premium-discount-elevated.component';
import { MoneyFundLiquidity } from '@products/models/money-fund-liquidity';
import { IndexesAllocation } from '../../interactive-content/us-index/us-index-allocations/us-index-allocations.config';

/**
 * ********** Pricing State Types **********
 */

export interface LegendSeries {
  seriesName: string;
  categoryNameStd: IndexesAllocation;
  color: string;
}

export type ChartConfig = DateRangeConfiguration & {
  chartTitle: string;
  proximalPlacementTop: string;
  proximalPlacementBottom: string;
  caveatPlacement: string;
  tooltip: string;
  asOfDate?: string;
  highChartOptions: Highcharts.Options;
  legendSeries?: LegendSeries[];
};

export interface PricingState {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  overviewData: {
    shareClass: ShareClass;
    nav: NAV;
    fundName: string;
    salesChargeBreakPoints: SalesChargeBreakPoint[];
  };
  historicalData: PricingHistory[];
  pricingConfig: PricingConfig;
  siteConfig: SiteConfig;
  chartConfig: ChartConfig;
  highLowNavTableData: HighLowNavData[];
  tableConfig: DateRangeConfiguration & PricingTableHistoricalSummary;
  hasLoadedFullFeed?: boolean;
  premiumDiscountAnalysis?: PremiumDiscountAnalysisData;
  premiumDiscountElevated?: PremiumDiscountElevatedPeriod[];
  premiumDiscountHistory?: ChartConfig;
  premiumDiscountNavViivHistory?: ChartConfig;
  isSiteIntl?: boolean;
  isTemit?: boolean;
  isMartinCurrieGpt?: boolean;
}

export interface PricingConfig {
  isSecondaryCalcTypeAvailable: boolean;
  suppressNavChangePercentage: boolean;
  dateValues: {
    incDate: string;
    incDateStd: string;
    asOfDateStd: string;
  };
  showPop: boolean;
  showMarketPriceData: boolean;
  showMoneyMarketPriceData: boolean;
  showPremiumDiscountData: boolean;
  showApplicationPrice: boolean;
  showRedemptionPrice: boolean;
  showForETF: boolean;
  showMoneyMarketNetFlowColumn: boolean;
  showMoneyMarketMasterNetFlowColumn: boolean;
  productType?: ProductType;
  showDailyLiquidAssetRatio?: boolean;
  showWeeklyLiquidAssetRatio?: boolean;
  showNetShareHolderFlow?: boolean;
  showMasterFundNav: boolean;
  hideIntraDayNav?: boolean;
  hidePricingChart: boolean;
}

export interface DateRangeConfiguration {
  dateRangeEnum: Map<string, ToggleOptionWithPeriod>;
  selectedToggleRange: string;
  customRange: {
    fromDateStd: string;
    toDateStd: string;
    fromDate?: string;
    toDate?: string;
  };
}

export type ToggleOptionWithPeriod = ToggleButton & {
  period?: {
    key: dayjs.UnitType;
    value: number;
  };
};

export interface PricingRangeUpdateValues {
  selectedToggleRange: string;
  fromDate?: string;
  toDate?: string;
}

export interface HighLowNavData {
  highestNav: string;
  lowestNav: string;
  highestNavDate: string;
  lowestNavDate: string;
  year: number;
}

export interface PricingTableHistoricalSummary {
  highestNav: string;
  lowestNav: string;
  highestNavDate: string;
  lowestNavDate: string;
  highestMarketPrice: string;
  lowestMarketPrice: string;
  highestMarketPriceDate: string;
  lowestMarketPriceDate: string;
}

export type InfoItemWithKey = InfoItem & {
  key: string;
};

/**
 * ********** Distribution State Types **********
 */

export interface DistributionState {
  fundName: string;
  historicalData: DistributionHistory[];
  siteConfig: SiteConfig;
  schedules?: DistributionSchedules;
  salesChargeBreakPoints?: SalesChargeBreakPoint[];
  yields?: Yields;
  latestConfig: DistributionLatestConfig;
  tableConfig: DistributionTableConfig;
  hasLoadedFullFeed?: boolean;
  isSiteIntl: boolean;
  productType?: string;
  latestDistributions: LatestDistribution[];
  selectedTab?: string;
  highChartOptions?: Highcharts.Options;
}

export interface DistributionNavState {
  fundName: string;
  historicalData: DistributionHistory[];
  pricingHistoricalData?: PricingHistory[];
  siteConfig: SiteConfig;
  schedules?: DistributionSchedules;
  salesChargeBreakPoints?: SalesChargeBreakPoint[];
  yields?: Yields;
  latestConfig: DistributionLatestConfig;
  tableConfig: DistributionTableConfig;
  hasLoadedFullFeed?: boolean;
  isSiteIntl: boolean;
  productType?: string;
  latestDistributions: LatestDistribution[];
  selectedTab?: string;
  highChartOptions?: Highcharts.Options;
}

export interface DistributionSchedules {
  distributionIndicator: string;
  dividendFrequency: string;
  capgainFrequency: string;
}

export interface DistributionLatestConfig {
  asOfDate: string;
  isSingleShareClass: boolean;
  shareClassName: string;
  shareClassInceptionDate: string;
  shareClassInceptionDateStd: string;
  currencyCode: string;
  navValue: string;
  incomeRecordDate: string;
  incomeExDividendDate: string;
  incomeDividendDate: string;
  incomeDistributionAmount: string;
  divDistributionIncome: string;
  dividendCapital: string;
  isSgHk: boolean;
  showCanadaDistForEtfMfMmf?: boolean;
  capitalGains?: string;
  hideExDistDate: boolean;

  // Below fields are optional, as specific to US.
  navDate?: string;
  reInvestmentDate?: string;
  capgainsRecordDate?: string;
  capgainsExDividendDate?: string;
  capgainsReinvestmentDate?: string;
  capgainsPayableDate?: string;
  reInvestmentAmount?: string;
  longTermCapitalGain?: string;
  shortTermCapitalGain?: string;
  taxableExemptIncome?: string;
  returnOfCapitalAmount?: string;
  totalDistributionAmountMonthly?: string;
  estimateFlag?: boolean;
  showIncomeOverCapgains?: boolean;
  asOfDateYear?: string;
}

export interface DistributionTableConfig {
  dividendYears: string[];
  selectedYear: string;
  startDate: string;
  endDate: string;
}

export interface DownloadableTableRowData {
  currency?: string;
  recordDate?: string;
  exDividendDate?: string;
  distributionDate?: string;
  nav?: string;
  distributionPerUnit?: string;
  incomeDistributionRecordDate?: string;
  capitalGainRecordDate?: string;
  incomeDistributionExDividendDate?: string;
  capitalGainExDividendDate?: string;
  reInvestmentDate?: string;
  capitalGainReInvestmentDate?: string;
  incomeDistributionExPayableDate?: string;
  capitalGainPayableDate?: string;
  reInvestmentAmount?: string;
  longTermCapitalGain?: string;
  shortTermCapitalGain?: string;
  incomeDistributionAmount?: string;
  taxExemptIncomeAmount?: string;
  returnOfCapitalAmount?: string;
  totalDistributionAmountMonthly?: string;
  divDistributionIncome?: string;
  dividendCapital?: string;
  exDividendNavValue?: string;
}

export interface PricingDownloadableTableRowData {
  asOfDate?: string;
  applicationPrice?: string;
  redemptionPrice?: string;
  nav?: string;
  navChangeValue?: string;
  navChangePercent?: string;
  marketPriceValue?: string;
  marketChangeValue?: string;
  marketChangePercent?: string;
  bidOrAskSpread?: string;
  discountOrPremiumInception?: string;
  discountOrPremiumAtLastClose?: string;
  masterFundNav?: string;
  dailyLiquidAssetRatio?: string;
  weeklyLiquidAssetRatio?: string;
  netShareHolderFlow?: string;
  masterNetShareHolderFlow?: string;
}

/**
 * ********** MMF Liquidity Types **********
 */

export interface LiquidityState {
  historicalData: MoneyFundLiquidity[];
  chartConfig: ChartConfig;
  asOfDate: string;
  hasLoadedFullFeed?: boolean;
}

/**
 * WDE-1621: Added as DistributionLatestConfig has too many fields which was not required to display current year distributions
 */
export interface LatestDistribution {
  incomeDividendDate: string;
  incomeRecordDate: string;
  capgainsRecordDate?: string;
  incomeExDividendDate: string;
  capgainsExDividendDate?: string;
  reInvestmentDate?: string;
  capgainsReinvestmentDate?: string;
  capgainsPayableDate?: string;
  reInvestmentAmount?: string;
  longTermCapitalGain?: string;
  shortTermCapitalGain?: string;
  returnOfCapitalAmount?: string;
  totalDistributionAmountMonthly?: string;
  incomeDistributionAmount: string;
  estimateFlag?: boolean;
  showIncomeOverCapgains?: boolean;
}
