<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container [brManageMenuButton]="menuModel"></ng-container>

  <ng-container>
    <div *ngIf="!!menu">
      <eds-footer
        [footerUpliftStyling]="true"
        [haslang]="langItems.length > 0"
        [langItems]="langItems"
        [langTitle]="languageLabel"
        [langTitleHref]="homeUrl"
        [langMenuWidth]="'auto'"
        [langMenuName]="footerParams.langMenuName"
        [hasSelectHub]="hasNavigation && hasSelectHub"
        [siteNavItems]="hasNavigation ? siteNavItems : null"
        [fullSiteNavigation]="hasfullSiteNavigation"
        [globalItems]="hasNavigation ? globalItems : null"
        [hasSocialMedia]="!!socialMediaItems"
        [socialMediaTitle]="
          hasNavigation && socialMediaItems?.length > 0
            ? footerParams.socialMediaTitle
            : ''
        "
        [socialMediaItems]="hasNavigation ? socialMediaItems : null"
        [copyrightAndImgLogo]="!hasTextLogo"
        [copyrightAndTextLogo]="hasTextLogo"
        [textLogo]="footerParams.textLogo"
        [altText]="footerParams.logoAltText"
        [imgSrc]="footerParams.imgSrc"
        [hasUserRole]="hasNavigation && userRoleItems.length > 0 ? true : false"
        [userRoleItemsDesktop]="userRoleItems"
        [roleCountryNavData]="hasNavigation ? roleNavData : null"
        [userRoleTitle]="currentSegmentLabel"
        [userRoleMenuWidth]="'auto'"
        [userRoleMenuName]="footerParams.userRoleMenuName"
        (userRoleSelected)="roleChanged($event)"
        [companyInfo]="companyInfo"
      >
        <div
          country-disclaimer
          *ngIf="hasNavigation && footerParams.countryDisclaimer != ''"
          [innerHTML]="footerParams.countryDisclaimer"
        ></div>

        <!-- NGC-1747 - change flyout to link to hub resources page -->
        <!-- TODO: Country-hub needs to be parametrized in site config -->
        <div country-select-hub>
          <a
            *ngIf="footerParams?.countryHubLink"
            class="md"
            [class.external-link--leaving-custom-external]="
              footerParams?.isLeavingSiteForCountryLinkClickDisplayed
            "
            edsLink
            [href]="footerParams.countryHubLink"
            [innerHTML]="siteName"
          ></a>
        </div>

        <div copyright [innerHTML]="footerParams.copyright"></div>
      </eds-footer>
    </div>
  </ng-container>
</div>
<!-- <ft-gateway-modal
  #gatewayModal
  (termsAccepted)="termsAccepted($event)"
></ft-gateway-modal> -->
<!-- Switch Segment Model -->
<eds-dialog [dialogId]="'segment-switch'" [config]="dialogConfig"></eds-dialog>
